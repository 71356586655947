//https://www.npmjs.com/package/smooth-scroll

import SmoothScroll from "smooth-scroll";

const smoothScroll = new SmoothScroll('a[href*="#"]', {
  ignore: "[data-vc-container]"
});

window.addEventListener('DOMContentLoaded', () => {

    if(window.location.hash) {
        let scroll = new SmoothScroll();
        let hash = window.location.hash.substring(1);
        let item = document.getElementById(hash);

        if (item) {
            scroll.animateScroll(item, null, {offset: 110});
            item.classList.add('highlight');
        }       
    }

});
import $ from "jquery";
import {isMobile} from './helper'
import SiteHeader from './siteHeader'
import {toggleMenu, addSticky, closeMenu} from './navigation'
import FloatLabels from './forms/float-labels';

let header = new SiteHeader();

window.addEventListener('DOMContentLoaded', () => {

    toggleMenu();
    FloatLabels();
    header.stickyHeader();


    (function(){

        let investmentType = document.getElementById('investment-type');
        let nipField = document.querySelector('.nip-field');

        investmentType.addEventListener('change', function(){
            if (this.value == 'company') {
                $(nipField).slideDown();
                $(nipField).find('input').removeAttr('disabled');
            } else {
                $(nipField).slideUp();
                $(nipField).find('input').attr('disabled', 'disabled');
            }
        });

    })();

    if (isMobile()) {
        closeMenu();
        addSticky();
    }

});

window.onresize = () => {

    header.stickyHeader();

    if (isMobile()) {
        closeMenu();
        addSticky();
    }

};
// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/****** SLIDERS *******/
const sliders = {
  home: $(".home-slider-container"),
  partners: $(".partners-slider")
};

sliders.home.slick({
	dots: true,
	arrows: false,
	autoplay: true,
	fade: true,
 	cssEase: 'linear',
 	pauseOnHover: false
});

sliders.partners.slick({
	dots: false,
	arrows: false,
	autoplay: true,
 	pauseOnHover: false,
 	slidesToShow: 5,
 	responsive: [
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 3,
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 2,
			}
		}
	]
});

import { closest } from '../helper';

const floatLabels = () => {
   
    let fields = document.querySelectorAll('.form-field input[type="text"], .form-field input[type="email"], .form-field input[type="tel"], .form-field textarea, .form-field select');
    let checkboxes = document.querySelectorAll('.form-field input[type="checkbox"]');

    fields.forEach(function(e) {
        if (e.value != "") {
           closest(e, '.form-field').classList.add('active');
        }

        e.addEventListener('focus', function() {
           closest(this, '.form-field').classList.add('active');
        });
    });

    fields.forEach(function(e) {
        e.addEventListener('blur', function() {
            if (this.value == "") {
               closest(this, '.form-field').classList.remove('active');
            }
        });
    });

    checkboxes.forEach(function(e) {
        e.addEventListener('change', function() {
            if (this.checked) {
               closest(this, '.form-field').classList.add('active');
            } else {
               closest(this, '.form-field').classList.remove('active');
            }
        });
    });
}

export default floatLabels;
import $ from "jquery";

window.addEventListener('DOMContentLoaded', () => {

    let auditForm = document.getElementById('audit-form');
    let doingAjax = false;

    if (auditForm) {
        auditForm.addEventListener('submit', e => { 

            e.preventDefault();

            let submitButton = auditForm.querySelector('input[type="submit"]');
            let formData = new FormData(auditForm);
            formData.append('action', 'process_audit_form');

            if (!doingAjax) {

                doingAjax = true;
                submitButton.classList.add('loading');

                $.ajax({
                    method: 'POST',
                    url: jsData.ajaxUrl,
                    data: formData,
                    type: 'POST',
                    processData: false,
                    contentType: false,
                    success: response => {
                        doingAjax = false;
                        submitButton.classList.remove('loading');
                        $('#audit-form-inner').slideUp('fast', function(){
                            $('.success-box').fadeIn('fast');
                        });
                    }
                });
            }

        });
    }

});